<template>
  <div id="app">
    <router-view />
    
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
@import url("./assets/css/comment.less");
#app {
  font-family: "ALBBPHT";
  color: #333;
}
</style>
