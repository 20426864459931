import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/privePDF",
    name: "privePDF",
    component: () => import("../views/privePDF.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/service.vue"),
  },
  {
    path: "/team",
    name: "team",
    component: () => import("../views/team.vue"),
  },
  {
    path: "/customer",
    name: "customer",
    component: () => import("../views/customer.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
